import { MainComponent } from './main/main.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { AccountloginComponentComponent } from './accountlogin-component/accountlogin-component.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { AuthGuardServiceService } from './auth-guard-service.service';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ValidatepasswordComponent } from './validatepassword/validatepassword.component';


const routes: Routes = [
  {
    path: 'location',
    canActivate: [AuthGuardServiceService],
    component: MainComponent,
    children: [
      // customers
      { path: 'users', component: CustomerListComponent },
      { path: 'users/user', component: CustomerdetailComponent },
      { path: 'users/user/:id', component: CustomerdetailComponent },

      // Edit Account
      { path: 'account', component: EditAccountComponent },
    ]
  },
  //login
  { path: '', component: AccountloginComponentComponent },
  { path: 'login', component: AccountloginComponentComponent },
  { path: 'forget', component: ForgetpasswordComponent },
  { path: 'reset/:email/:randNo', component: ResetpasswordComponent },
  { path: 'validate/:id/:random', component: ValidatepasswordComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
