import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HttpClientModule } from '@angular/common/http';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { AccountloginComponentComponent } from './accountlogin-component/accountlogin-component.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ValidatepasswordComponent } from './validatepassword/validatepassword.component';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    MainComponent,
    CustomerdetailComponent,
    CustomerListComponent,
    EditAccountComponent,
    ForgetpasswordComponent,
    AccountloginComponentComponent,
    ResetpasswordComponent,
    ValidatepasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
