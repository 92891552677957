import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardServiceService implements CanActivate {

  constructor() { }
  async canActivate() {
    return ((sessionStorage.getItem("etrLocUser") && sessionStorage.getItem("etrLocUser").length)) ? true : false
  }
}

